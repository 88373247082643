
.Mui-selected  {
    outline: none  !important ;                                                                 
  }
  .recruiter-tab{
    .MuiTabs-flexContainer {
      display: flex !important;
      width: 100% !important;
      justify-content: space-around !important;
    }

  }
  

  .recru{
        cursor: pointer;    
}


