@import "../../../style/common/globl";
.main_notification {
  width: 100%;
  background-color: #ffefe5;
}

.warp_text {
  word-wrap: break-word;
}

.contact_number_color{
  color: #FF6B17;
}
.viewdetailsbtn {
  padding-bottom: 5px;
  width: 100%;
  text-align: center;
  position: relative;
  button.viewdetails {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    color: $blue_color;
    margin-top: 5px;
    &:hover {
      color: $blue_color_hover;
      text-decoration: underline;
    }
  }
}
.Interview_heading {
  text-align: center;
  margin-bottom: 15px;
}

.interviewList {
  h6 {
    font-size: 14px;
  }
}
