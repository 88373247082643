$black: #1E1F21;
$white:  #ffffff;

$blue_color: #0678c1;
$blue_color_hover: #0b9fe1;

$orange_color: #eb621e;
$orange_color_hover: #fdce06;

// common-classes
.black-color{
  color: $black!important;
}
.blogs_main {
  margin-top: 71px;
  padding: 50px 0px;
}
.blogs_main_inner {
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
  transition: background-image 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
}
.blogs_heading {
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.blog_heading_img, .blogs_heading_inner {
  width: 50%;
}
.blog_heading_img {
  border-radius: 4px 0px 0px 0px;
}
.blogs_heading_inner {
  padding: 30px;
  padding-bottom: 0;
}
.blogs_heading_inner h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: $blue_color;
  margin-bottom: 30px;
}
.blogs_heading_inner p {
  font-size: 16px;
  color: #948A99;
  line-height: 26px;
  margin-bottom: 30px;
}
.blogs_heading_inner p:last-child {
  margin-bottom: 0;
}
.blogs_heading_inner p span {
  color: $blue_color;
  font-weight: 700;
}
.blogs_heading_inner p span a {
  color: $blue_color;
}
.blogs_heading_inner p span a:hover {
  color: $blue_color_hover;
}
.blogs_content {
  padding: 50px 50px 80px 50px;
  border-radius: 0px 0px 4px 4px;
}
.blogs_content h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: $blue_color;
  margin-bottom: 30px;
}
.blogs_content p {
  font-size: 16px;
  color: #948A99;
  line-height: 26px;
  margin-bottom: 30px;
}
.blogs_content p span {
  color: $blue_color;
  font-weight: 700;
}
.blogs_content p span a {
  color: $blue_color;
}
.blogs_content p span a:hover {
  color: $blue_color_hover;
}
.blogs_content ul {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 30px;
}
.blogs_content ul li {
  font-size: 16px;
  color: #948A99;
  line-height: 1.6em;
  margin-bottom: 15px;
}
.blogs_content ul li span {
  color: $black;
  font-weight: 600;
}
.blogs_content ul li span a {
  color: $blue_color;
}
.blogs_content ul li span a:hover {
  color: $blue_color_hover;
}
.condition_content ul li span {
  color: $blue_color;
}
@media screen and (max-width: 1199px){}
@media screen and (max-width: 991px){}
@media screen and (max-width: 767px){
  .blogs_heading {
    padding: 30px;
    padding-bottom: 0px;
    display: block;
  }
  .blog_heading_img, .blogs_heading_inner {
    width: 100%;
  }
  .blogs_heading_inner {
    padding: 30px 0px 0px 0px;
  }
  .blogs_heading_inner p:last-child {
    margin-bottom: 30px;
  }
  .blogs_content {
    padding: 0px 30px 30px 30px;
  }
  .blogs_content h3, .blogs_heading_inner h3 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .blogs_content p, .blogs_heading_inner p {
    margin-bottom: 20px;
  }
  .blogs_heading_inner p:last-child {
    margin-bottom: 20px;
  }
  .blogs_content ul {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 575px){
  .blogs_main {
    padding: 30px 0px;
  }
}
@media screen and (max-width: 414px){
  .blogs_heading {
    padding: 0px;
  }
  .blogs_content h3, .blogs_heading_inner h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .blogs_content, .blogs_heading_inner {
    padding: 15px 15px 0px 15px;
  }
  .blogs_content h3, .blogs_content p, .blogs_content ul, .blogs_heading_inner h3, .blogs_heading_inner p {
    margin-bottom: 15px;
  }
  .blogs_heading_inner p:last-child {
    margin-bottom: 15px;
  }
}