@import "../../../style/common/globl";
.main_headfooter {
    padding: 0;
    margin: 0;
    width: 100%;
    background: #fff;
    box-shadow: 2px 5px 10px 5px #ddd;
    border-top: 1px solid #ccc;
    z-index: 9990;
  }
  
  .dashboard_menu {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
  }
  .nav_aa {
    color: #000000;
  }
  
  
  .icon_uu {
    font-size: 18px !important;
    // color: #000000;
  
  }
  

  .common_dash{
    color: #000000;
  }

  .common_dash_one{  
    color: $dark_blue;
  }


  .dashboard_menu_one {
    @extend .dashboard_menu;
    
  }



  .nav_aa {
    text-decoration: none !important;
  }
  
  .menu_active {
    color: $dark_blue !important;
  }
  .notification_link {
    position: relative;
    .notification_count{
      position: absolute;
      top: -7px;
      right: 10px;
      color: #fff;
      font-size: 12px;
      font-weight: 900;
      background-color: #e64a00;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 50%;
      padding: 1px;
    }
  }
 
  // .a{
  //   color:black !important;
  // }


  