$blue_color: #0678c1;
$blue_color_hover: #0b9fe1;
.common_btn {
  width: 100%;
  padding: 2% 20% 2% 20%;
  border: none;
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(253, 206, 6, 1) 0%, rgba(255, 107, 23, 1) 100%);
  //    background-color:red;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  outline: none !important;
}
.video_btn {
  display: inline-block;
  color: #0678c1;
  margin-right: 10px;
  font-size: 24px;
}
.video_btn:hover {
  color: #0b9fe1;
}
.btn_icon {
  margin: 0 4px;
}
.close_btn {
  position: fixed;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 24px;
}
.close_btn:hover {
  color: #e0e0d8;
}
.resume_btn {
  cursor: pointer;
  background: linear-gradient(90deg, rgba(11, 159, 225, 1) 1%, rgba(6, 120, 193, 1) 100%);
  color: white;
  border-radius: 4px;
  padding: 6px 10px;
  transition: all 0.35s;
  min-width: 95px;
}
.resume_btn:hover {
  background: linear-gradient(90deg, rgba(6, 120, 193, 1) 1%, rgba(11, 159, 225, 1) 100%);
  transition: all 0.35s;
}
.resume_btn:disabled {
  background: #d2d9dceb;
  cursor: not-allowed;
  color: black;
}
.audio-video_btn {
  cursor: pointer;
  font-size: 56px;
  color: $blue_color_hover;
}
.audio-video_btn:hover {
  color: $blue_color;
}
.audio-video_btn:disabled {
  color: #999999;
  cursor: not-allowed;
}
