$dark_blue: #0678c1;
$yellow: #f6b217;
$orange: #ff6b17;
$light_blue: #0b9fe1;
$light_grey:#ccc;
$grey: grey;
$light-pink: #FFEFE5;
$blue-color: #0678c1;
$white: #ffffff;
// $black: #000000;
// $white: #ffffff;

$black: #1e1f21;
$white: #ffffff;
$blue_color: #0678c1;
$blue_color_hover: #0b9fe1;
$orange_color: #eb621e;
$orange_color_hover: #fdce06;
$blue_color: #0678c1;

$grey: #999999;
$blue:#0678C1;
// $blue:#0678C1;
// $blue:#0678C1;

.sha {
  box-shadow: 0px 4px 8px 4px #ddd;
}

@media (max-width: 768px) {
  .sha {
    box-shadow: none;
  }
}
