.add_other_field {
  align-items: center;
  margin-bottom: 20px;
  input.other_Skills_Input {
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 12px;
    min-height: 46px;
    position: relative;
  }
  svg {
    cursor: pointer;
  }
  svg.disabled {
    color: #999 !important;
    cursor: not-allowed;
  }
  p {
    font-size: 14px;
    text-align: left;
    margin-top: 5px;
  }
}
.verticalscroll {
  overflow-y: scroll;
  height: 340px;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.10px);
    border-radius: 0px;
    background-color: #0678c1;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.10px);
    border-radius: 0px;
    background-color: #ffffff;
  }
  .optionListContainer {
    position: relative !important;
  }
}

@media( max-width: 415px) {
  .add_other_field input.other_Skills_Input {
    width: 80%;
  }
}