.MuiBox-root-10 {
    padding: 0 !important;
}

.bgb{
    background: linear-gradient(
        90deg,
        rgba(11, 159, 225, 1) 100%,
        rgba(6, 120, 193, 1) 100%)
}
.tabcount {
    background: #0b9fe1;
    color: #fff;
    padding: 0px 4px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    line-height: 20px;
    height: 20px;
    width: 20px;
    vertical-align: middle;
}
.tabUI .MuiTab-root {
    min-width: 110px;
}