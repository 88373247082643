@import "../../../style/common/globl";
@import "../../../style/common/btn";


.ya {
  font-size: 15px;
  font-weight: 500;
  color: $dark_blue;
}

.yb {
  @extend .ya;
  font-size: 12px;
  font-weight: 400;
  color: $light_blue;
}

.btn_bdr {
  width: 100%;
  background-color: #ffff;
  box-shadow: 2px 4px 8px 4px #ddd;
}

.in{
  color:$dark_blue !important;
  // font-weight:400;
  font-size:15px;
}

.in_a{
       @extend .in;
       font-size:18px;
       padding:0;
       margin:0;
       font-weight:400;
}

// .pstt{ 
//         width:100%;
//         background-color:red;
//          position: absolute;
//          bottom:0;
//          left:0;

// }

// .supert_pst{
//      position: relative;
// }

.job_catt{
  height:300px;
  overflow:scroll;
}

.inter_o{
  font-size:12px !important;
}

.other_input{
   width:100%;
}