 @import "mediaotp.scss";
 .top_main{
     width:100%;
  .otp_ver{
       font-size:20px;
       font-weight:300;
       color:#000000;
       letter-spacing:1px;
   } 
   
   .otp_input{
          width:100%;
          border:1px solid #ccc;
          outline:none;
          font-size:15px;
          font-weight:capitalize;
          padding-left:10px;
          
   }

   .otp_tc{
         padding:0;
         margin:0;
         font-size:16px;
   }
}