$black: #1E1F21;
$white:  #ffffff;

$blue_color: #0678c1;
$blue_color_hover: #0b9fe1;

$orange_color: #eb621e;
$orange_color_hover: #fdce06;

.aboutus {
  margin-top: 71px;
  background: url(../../../assets/images/about/About_Us_1920x720.png) center center/cover no-repeat fixed;
  position: relative;
}

.aboutus::before {
  background-color: rgba(42, 46, 50, 0.7);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  content: '';
}

.aboutus_inner {
  padding: 100px 0px;
}

.aboutus_inner .about-text {
  color: $black;
  font-weight: 700;
  font-size: 44px;
  line-height: 44px;
  text-align: center;
  background-color: $white;
  width: max-content;
  margin: 0 auto 30px;
  padding: 5px 10px;
  border-radius: 4px;
}
.aboutus_inner .about-text span {
  color: $blue_color;
  font-style: italic;
}
.aboutus_inner .about-tag {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
  background: linear-gradient(90deg, #eb621e, #fdce06);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.aboutus_inner p {
  font-size: 20px;
  font-weight: 500;
  // color: #948a99;
  color: $white;
  margin-bottom: 15px;
  text-align: center;
}

.vm_content, .vm_img {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.vision_mission {
  padding: 50px 0px;
  background-color: $white;
}

.vm_content h3 {
  font-size: 38px;
  line-height: 38px;
  font-weight: 700;
  color: $black;
  margin-bottom: 15px;
  text-align: center;
}

.vm_content h3 span {
  color: $blue_color;
}

.vm_content p {
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
  color: #948a99;
  text-align: left;
}
@media screen and (max-width: 1199px){
  .aboutus_inner h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 991px){
  .aboutus {
    background: url(../../../assets/images/about/About_Us_991x513.png) center center/cover no-repeat fixed;
    background-size: cover;
  }
  .vm_content h3 {
    font-size: 32px;
    line-height: 32px;
  }
  .vm_content p {
    font-size: 16px;
    line-height: 24px;
  }
  .aboutus_inner h2 {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px){
  .aboutus {
    background: url(../../../assets/images/about/About_Us_767x513.png) center center/cover no-repeat fixed;
  }
  .aboutus_inner p {
    font-size: 16px;
  }
  .vm_content h3 {
    font-size: 28px;
    line-height: 28px;
  }
}
@media screen and (max-width: 575px){
  .aboutus {
    background: url(../../../assets/images/about/About_Us_575x513.png) center center/cover no-repeat fixed;
  }
  .vision_mission {
    padding: 30px 0px;
  }
}
@media screen and (max-width: 414px){
  .aboutus {
    background: url(../../../assets/images/about/About_Us_415x513.png) center center/cover no-repeat fixed;
  }
  .aboutus_inner {
    padding: 50px 0px;
  }
  .aboutus_inner .about-text {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .aboutus_inner .about-tag {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 320px){ 
  .aboutus {
    background: url(../../../assets/images/about/About_Us_320x460.png) center center/cover no-repeat fixed;
  }
}
