@import "../../../style/common/globl";

jk {
  font-size: 15px;
  font-weight: 500;
}

.jk {
  font-size: 12px;
  font-weight: 400;
}

.ji {
  font-weight: 600;
  font-size: 14px;
}

.yy {
  position: absolute;
  top: 5px;
  right: 15px;
}

.uu {
  position: relative;
  background-color: #ffefe5;
}

.status_ac {
  border: 1px solid red;
  border-radius: 25px !important;
}

.Text_title_size {
  width: 160px;
  word-wrap: break-word;
  text-align: left;
}

.Text_location_size {
  width: 150px;
  word-wrap: break-word;
  text-align: left;
  font-weight: 600;
}

.chipps {
  cursor: pointer;
  border: 1px solid #eb621e;
  background-color: #eb621e;
  color: #fff;
  padding: 3px 5px;
  border-radius: 20px;
  font-size: 10px;
  margin-top: 5px;
  min-width: 65px;
  display: inline-block;
}

.vjd_btn {
  font-size: 14px;
  line-height: 14px;
  background: linear-gradient(90deg, #0678c1 0%, #0b9fe1 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
}
.vjd_btn_icon {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #0678c1;
}
.jobstatus_txt {
  color: #0678c1;
}
//  Css for Page Heading
.page_title{
  color: #0678c1;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
  text-align: center
}
.apply-filter-btn {
  background-color: $blue !important;
}