@import "../../../style/common/notification.scss";
.MuiBox-root-10 {
    padding: 0;
}

.main_notification {
    width: 100%;
    background-color: #ffefe5;
}

.noti_user {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
}

.btn_noti {
    padding: 4px;
    border: 1px solid $light_blue;
    text-transform: capitalize;
    font-weight: 400;
    color: $light_blue;
}

.user_ua,
.user_ub,
.user_uc {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: grey;
    word-wrap: break-word;
}

.user_nme {
    font-size: 16px !important;
    font-weight: 400;
    word-wrap: break-word;
}

.post_time {
    font-size: 10px;
    color: grey;
    text-align: center;
}

.fnt_icon {
    font-size: 15px !important;
}

.viewoptionsbtn{
    position: relative;
}
.viewoptionsbtn button.moreoptns svg {
    pointer-events: none;
}

.viewoptionsbtn .dropdownbox {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    top: 0;
    bottom: unset;
}
.viewoptionsbtn .dropdownmenu {
    bottom: unset;
    right: -10px;
    top: -10px;
    z-index: 999;
}
.viewoptionsbtn .dropdownmenu ul {
    padding: 8px 0px;
}
.viewoptionsbtn .dropdownmenu ul li a {
    padding: 8px 16px;
}
.viewoptionsbtn .option_menu .view_menu_icons {
    margin-right: 5px;
}
.padryt {
    padding-right: 20px;
}
// css for dashboard card
.common-card-padding{
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}