h1 {
  color: red;
}

.community_main {
  width: 100%;
  overflow: hidden!important;
  .community_inner {
    margin-top: 61px;
    margin-bottom: 60px;
  }
  .playlist_box_main {
    padding: 0px 15px;
    h2 {
      color: #0678c1;
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 20px;
      text-align: center;
      background: linear-gradient(90deg,#eb621e,#fdce06);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 600;
    }
    .playlist_box {
      ul {
        list-style-type: none;
      }
      li.videoBox {
        border: 1px solid #ccc;
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 20px;
        // background-color: #ffefe5;
        background-color: rgba(0, 0, 0, 0.10);
        position: relative;
        a {
          cursor: pointer;
          &:hover {
            color: #0678c1;
          }
        }
        h3 {
          font-size: 18px;
          margin: 0px 0px 10px;
          color: #0678c1;
        }
        .video_play_box {
          display: block;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
        .video_thumb {
          width: 100%;
          height: 100%;
        }
        .play_icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 70px;
          height: 70px;
          background: #fff;
          border-radius: 50%;
          outline: 0px;
          transition: all 0.35s;
          &:hover {
            outline: 10px solid rgba(191, 187, 187, 0.4);
            transition: all 0.35s;
          }
          &:hover svg {
            color: #FF0000;
            transition: all 0.35s;
          }
          svg {
            font-size: 30px;
            color: #282828;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-45%, -50%);
          }
        }
        p.videoDescription {
          font-size: 14px;
          color: #495057;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 10px;
        }
        .videoDetailBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          margin-top: 10px;
        }
        .videoDate {
          font-size: 12px;
          color: #495057;
          font-weight: 600;
          i {
            font-size: 14px;
            color: #0678c1;
            font-style: normal;
          }
        }
        .sharebox {
          font-size: 12px;
          color: #495057;
          font-weight: 600;
          padding-right: 40px;
          position: absolute;
          right: 0px;
          bottom: 0px;
          .menu.topRight {
            top: -6px;
            .share_icon {
              color: #212529;
              background-color: transparent;
              &:hover {
                background: linear-gradient(90deg, #0b9fe1 0%, #0678c1 100%);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.sharebox .menu .share.bottom .submenu-active {
  @for $i from 1 through 6{
    li:nth-child(#{$i}){
      opacity:1;
      top: -#{$i*40}px;
      transform:rotate(0deg);
      transition-delay:#{$i*0.08}s;
    }
  }
}

.inpt {
  width: 100%;
  padding: 2% 5% 1% 5%;
  border: 1px solid #ccc;
  border-radius: 100px;
  outline: none;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
}

.m_input {
  width: 80%;
}
.comm_aa {
  padding: 0;
  margin-top: 4px;
  color: #0678c1;
}

.comm_ab {
  padding: 0;
  margin: 0;
  color: black;
}

.bo {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.zz {
  z-index: 8888;
}

@media screen and (max-width: 560px){
  .community_main .playlist_box_main h2 {
    font-size: 20px;
  }
  .community_main .playlist_box_main .playlist_box li.videoBox h3 {
    font-size: 16px;
  }
  .community_main .playlist_box_main .playlist_box li.videoBox p.videoDescription {
    font-size: 12px;
  }
  .community_main .playlist_box_main .playlist_box li.videoBox .play_icon {
    width: 50px;
    height: 50px;
  }
  .community_main .playlist_box_main .playlist_box li.videoBox .play_icon svg {
    font-size: 20px;
  }
}