$black: #1E1F21;
$white:  #ffffff;

$blue_color: #0678c1;
$blue_color_hover: #0b9fe1;

$orange_color: #eb621e;
$orange_color_hover: #fdce06;

.privacy {
  margin-top: 71px;
  padding: 50px 0px;
}
.privacy_inner {
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
  transition: background-image 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
}
.privacy_heading {
  background-image: linear-gradient(45deg,$blue_color 0%,$blue_color_hover 100%);
  padding: 50px;
  border-radius: 4px 4px 0px 0px;
}
.privacy_heading h3 {
  font-size: 38px;
  font-weight: 500;
  color: #ffffff;
}
.privacy_content {
  padding: 80px 50px 80px 50px;
  border-radius: 4px;
  border: 1px solid #E3E3E3;
  box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
  transition: background-image 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
}
.privacy_content h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: $blue_color;
  margin-bottom: 30px;
}
.privacy_content p {
  font-size: 16px;
  color: #948A99;
  line-height: 26px;
  margin-bottom: 30px;
}
.privacy_content p span {
  color: $blue_color;
  font-weight: 700;
}
.privacy_content p span a {
  color: $blue_color;
}
.privacy_content p span a:hover {
  color: $blue_color_hover;
}

.privacy_content ul {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 30px;
}
.privacy_content ul li {
  font-size: 16px;
  color: #948A99;
  line-height: 1.6em;
  margin-bottom: 15px;
}
.privacy_content ul li span {
  color: $black;
  font-weight: 600;
}
.privacy_content ul li span a {
  color: $blue_color;
}
.privacy_content ul li span a:hover {
  color: $blue_color_hover;
}
.condition_content ul li span {
  color: $blue_color;
}

@media screen and (max-width: 1199px){}
@media screen and (max-width: 991px){}
@media screen and (max-width: 767px){
  .privacy_heading {
    padding: 30px;
  }
  .privacy_content {
    padding: 50px 30px 30px 30px;
  }
  .privacy_content h3 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .privacy_content p {
    margin-bottom: 20px;
  }
  .privacy_content ul {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 575px){
  .privacy {
    padding: 30px 0px;
  }
  .privacy_heading h3 {
    font-size: 28px;
  }
}
@media screen and (max-width: 414px){
  .privacy_heading {
    padding-left: 15px;
  }
  .privacy_heading h3 {
    font-size: 24px;
  }
  .privacy_content h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .privacy_content {
    padding: 15px 15px 0px 15px;
  }
  .privacy_content h3, .privacy_content p, .privacy_content ul {
    margin-bottom: 15px;
  }
}