@media screen and (max-width: 1199px){
  .nav_lft {
    width: auto;
  }
  .nav_right {
    width: auto;
  }
  .logo {
    width: auto;
  }
  .slider {
    padding: 0px 30px;
  }
  .je_content {
    padding: 20px;
  }
  .je_inner h3 {
    font-size: 22px;
  }
  .je_inner h2, .work_inner h2, .blogs_inner h2, .faq_inner h2 {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 32px;
  }
  .blogs_inner h2 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 991px){
  .header {
    padding: 15.5px 15px;
  }
  .header_menu li {
    margin: 0px 2px;
  }
  .header_menu a {
    padding: 6px 5px;
  }
  .nav_right .signupbtn {
    padding: 5px 5px;
  }
  .nav_right .signupbtn {
    display: none;
  }
  .landing_main {
    background: url(../../../assets/images/homepage/Website-banner-991-x513.png) no-repeat center center;
    background-size: 100% 100%;
  }
  .landing_inner a.scrollbtn {
    bottom: 45px;
  }
  .je_inner {
    padding: 0px 0px;
  }
  .je_inner h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .faq_list {
    padding: 30px;
  }
  .faq_list .MuiAccordionSummary-content {
    padding: 22px 0px;
  }
  .faq_list .MuiAccordionSummary-content p {
    font-size: 16px;
    line-height: 16px;
  }
  .about p {
    font-size: 16px
  }
  .fm-list {
    padding: 0px 0px;
  }
  .wi_content_box {
    width: 300px;
    height: 300px;
    padding: 20px;
  }
  .work_content {
    padding: 5px 5px;
  }
  .work_content img {
    width: 50px;
    margin-bottom: 10px;
  }
  .work_content h3 {
    font-size: 16px;
  }
  .b_content {
    min-height: 202px;
    height: 202px;
  }
}
@media screen and (max-width: 767px){
  .header .hamburger{
    display: block;
  }
  .nav_lft {
    width: auto;
  }
  .nav_middle {
    display: none;
  }
  .nav_right {
    width: auto;
  }
  .logo {
    width: auto;
  }
  .nav_right .signupbtn {
    padding: 5px 10px;
  }
  .landing_main {
    background-size: cover;
  }
  .je_content {
    flex-direction: column;
  }
  .je_content img.img_job {
    margin-bottom: 10px;
  }
  .jobexplorer, .work, .blogs, .faq, .footer_social, .f_inner {
    padding: 30px 0px;
  }
  .wi_content_box {
    margin-top: 30px;
    width: 400px;
    height: 400px;
    padding: 30px;
  }
  .work_content img {
    width: 90px;
  }
  .work_content h3 {
    font-size: 18px;
  }
  .blogs_inner h2 {
    margin-bottom: 0px;
  }
  .b_content {
    min-height: 257px;
    height: 257px;
  }
  .b_content h3 {
    font-size: 16px;
  }
  .b_content a.custombtn {
    padding: 10px 10px;
  }
  .je_inner h2, .work_inner h2, .blogs_inner h2, .faq_inner h2 {
    font-size: 28px;
    line-height: 28px;
  }
  .faq_list {
    padding: 15px;
  }
  .faq_list .MuiAccordionSummary-content {
    padding: 12px 0px;
  }
  .faq_list .MuiAccordionSummary-content p {
    flex-basis: 100%;
    padding-right: 15px;
    font-size: 16px;
    line-height: 26px;
  }
  .faq_list .MuiAccordionDetails-root p {
    font-size: 16px;
    line-height: 26px;
  }
  .faq_list .points {
    font-size: 16px;
    line-height: 26px;
  }
  .footer_logo img {
    margin: 0 auto 30px;
  }
  .about {
    margin-bottom: 20px;
  }
  .about p {
    text-align: center;
  }
  .about h3, .footer_menu h3, .contact_form_heading h3 {
    text-align: center;
  }
  .footer_menu {
    margin-bottom: 20px;
  }
  .fm-list {
    display: block;
  }
  .footer_menu ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .footer_menu li {
    margin: 10px 10px 10px 10px;
  }
  .footer_menu li a:hover {
    transform: translate(0px, 0px);
  }
  .footer_menu a::before {
    content: '' !important;
    margin-right: 0;
  }
  .contact_form button.custombtn {
    margin: 0 auto;
    display: block;
  }
}
@media screen and (max-width: 575px) {
  .landing_inner {
    height: 100vh;
  }
  .landing_main {
    background: url(../../../assets/images/homepage/Website-banner-575-x513.png) no-repeat center center;
    background-size: 100% 100%;
  }
  .je_inner h3 {
    font-size: 18px;
  }
  .je_content a.custombtn {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    min-width: 140px;
    max-width: 145px;
  }
  .b_content {
    min-height: 160px;
    height: 160px;
  }
  .b_content a.custombtn {
    letter-spacing: 0.5px;
  }
  .work {
    background-size: 60% 100%;
  }
  .wi_content_box {
    margin-top: 30px;
    width: 350px;
    height: 350px;
    padding: 20px;
  }
  .work_content img {
    width: 80px;
    margin-bottom: 10px;
  }
  .work_content h3 {
    font-size: 18px;
    letter-spacing: 0.5px;
  }
  .tollfree_number {
    width: 100%;
    right: 0px;
    top: 71px;
    font-size: 16px;
    text-align: center;
    padding: 10px 10px;
    border-radius: 0px;
    span {
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      margin-left: 5px;
    }
  }
  .landing_whatsapp_icon {
    left: 30px;
    top: 130px;
}
}

@media screen and (max-width: 480px) {
  .landing_main {
    background-size: 480px 100%;
  }
}
@media screen and (max-width: 445px) {
  .b_content {
    min-height: 180px;
    height: 180px;
  }
  .blog_img img {
    height: 100%;
  }
}
@media screen and (max-width: 414px){
  .landing_main {
    background: url(../../../assets/images/homepage/Website-banner-415-x513.png) no-repeat center center;
    background-size: 414px 100%;
  }
  .landing_inner h1 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .je_inner h2, .work_inner h2, .blogs_inner h2, .faq_inner h2 {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .faq_inner h2 {
    line-height: 28px;
  }
  .je_inner .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .je_content {
    margin-bottom: 30px;
  }
  .je_content.marginbtn {
    margin-bottom: 0px;
  }
  .blogs_inner h2 {
    margin-bottom: 0px;
  }
  .slider {
    padding: 0px 15px;
  }
  .slick-prev {
    left: -25px !important;
  }
  .slick-next {
    right: -25px !important;
  }
  .slick-prev, .slick-next {
    width: 25px !important;
    height: 25px !important;
  }
  .slick-prev:before, .slick-next:before {
    width: 25px;
    height: 25px;
    background-size: 12px 12px;
  }
  .footer_logo img {
    width: 200px;
  }
  .about p {
    text-align: center;
  }
  .faq_list .MuiAccordionDetails-root p {
    font-size: 16px;
    line-height: 26px;
  }
  .faq_list .points {
    font-size: 16px;
    line-height: 26px;
  }
  .videobox iframe {
    height: 200px;
  }
  .wi_content_box {
    margin-top: 30px;
    width: 290px;
    height: 290px;
    padding: 20px;
  }
  .work_content {
    width: 100%;
    padding: 0px;
  }
  .work_content img {
    width: 60px;
  }
  .work_content h3 {
    font-size: 16px;
  }
  .nav_right li.res_hide {
    display: none;
  }
  .sidenav ul li.res_hideshow {
    display: block;
  }
  .sidenav a {
    font-size: 15px;
    line-height: 15px;
  }
  .toast-body {
    font-size: 18px;
  }
  .b_content {
    min-height: 180px;
    height: 180px;
  }
  .contact-modal .MuiDialogContent-root {
    padding: 20px;
  }
  .footer_address p:first-child {
    font-size: 13px;
  }
  .contact-us-heading h5 {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 355px){
  .b_content {
    min-height: 220px;
    height: 220px;
  }
}
@media screen and (max-width: 320px){
  .landing_main {
    background: url(../../../assets/images/homepage/Website-banner-320-x-460.png) no-repeat center center;
    background-size: cover;
  }
}