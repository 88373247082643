@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
*{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
h1,h2,h3,h4,h5,h5,p {
    font-family: 'Roboto', sans-serif;
}
body {
    margin-bottom:65px;
}
.st {
    margin-top:80px!important;
}
.p_bottom {
    padding-bottom: 60px;
}
.row_padding_bottom {
    padding-bottom: 8px;
}