.interview_form_box {
  label {
    font-size: 14px;
    color: #0678c1;
    font-weight: 500;
  }
  .MuiInputBase-root {
    font-size: 1rem;
    color: #495057;
  }
  .MuiAutocomplete-input::placeholder {
    color: #6c757d !important;
  }
  input.form-control:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
  input.form-control:focus {
    box-shadow: none;
    border-color: #0678C1;
  }
  textarea.form-control {
    resize: none;
  }
  textarea.form-control:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
  textarea.form-control:focus {
    box-shadow: none;
    border-color: #0678C1;
  }
}
.timelabel {
  display: flex;
  align-items: center;
}
.form-group .req_doc_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-group .req_doc_box  .form-control{
  width: 90%;
}

.req_doc_box .addicon {
  font-size: 20px;
  color: #0678C1;
}