
.menu{
    z-index:999;
    position:absolute;
    padding:0;
    margin:0;
    list-style-type:none;
    .share{
      cursor: pointer;
      .icon{
        height:30px;
        width:30px;
        text-align:center;
        line-height:25px;
        background-color: #fff;
        border-radius:2px;
        padding: 4px;
        cursor: pointer;
      }
        &.bottom {
          .submenu-active{
            @for $i from 1 through 6{
              li:nth-child(#{$i}){
                opacity:1;
                top:#{$i*40}px;
                transform:rotate(0deg);
                transition-delay:#{$i*0.08}s;
              }
            }
          }
        }
    }
    .share_icon {
      color: #212529;
      &:hover {
        background: linear-gradient(90deg, #0b9fe1 0%, #0678c1 100%);
        color: #fff;
      }
    }
    .submenu-active{
      list-style-type:none;
      padding:0;
      margin:0;
      li{
        transition:all ease-in-out 0.5s;
        position:absolute;
        top: 0;
        left: 0;
        z-index:-1;
        opacity:0;
        .react-share__ShareButton{
          color: #212121;
          &:hover{
            .icon{
              color:#fff;
            }
            .fb_icon{
                background-color: #3b5999;
            }
            .twitter_icon{
                background-color: #55acee;
            }
            .email_icon{
                background-color: #464b55;
            }
            .linkedin_icon{
                background-color: #2d7fb1;
            }
            .whatsapp_icon{
                background-color: #75d567;
            }
          }
        }
      }
      @for $i from 1 through 4{
        li:nth-child(#{$i}){
          transform:rotateX(#{$i*45}deg);
        }
      }
    }
    &.topRight{
      top:0;
      right:5px;
    }
  }

.shareListInner {
  display: none;
}
.submenu-active .shareListInner {
  display: block;
}