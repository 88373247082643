@import "../../style/common/globl";
.main_user {
  width: 100%;
}
.main-section {
  background-color: #fff;
}
.user-detail {
  margin: -50px 0px 30px 0px;
}
.user-detail img {
  height: 100px;
  width: 100px;
}
.user-detail h5 {
  margin: 15px 0px 5px 0px;
}
.user-social-detail {
  padding: 15px 0px;
  background-color: #0b9fe1;
}
.user-social-detail a i {
  color: #fff;
  font-size: 23px;
  padding: 0px 5px;
}
.progress-bar {
  background-color: #0b9fe1;
}
.p1 {
  font-size: 14px;
  font-weight: 400;
  color: $black;
  text-transform: capitalize;
}
.p11 {
  font-size: 14px;
  font-weight: 400;
  color: $black;
}
.fa {
  color: #0b9fe1;
}
.p3 {
  color: red;
  margin-left: -131px;
}
.btn-secondary {
  border-radius: 16px;
  float: left;
}
.ltr_clr {
  color: $black;
  word-spacing: 2px;
}
.profile_field_box {
  margin-top: 10px;
}
.label_box {
  display: flex;
  justify-content: space-between;
}
.user_icon {
  font-size: 18px !important;
  color: $dark_blue;
  margin-right: 5px;
  float: left;
}
.pencil {
  font-size: 18px !important;
  margin-right: 5px;
  color: $yellow;
}
.user_one {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  background: linear-gradient(90deg, rgba(6, 120, 193, 1) 100%, rgba(11, 159, 225, 1) 100%);
  background-clip: text;
  color: transparent;
  padding: 5px 5px 5px 0px;
}
.user_two {
  @extend .user_one;
}
.user_tt {
  margin: 0;
  word-spacing: 1px;
  text-align: left;
  border-radius: .25rem;
  padding: .375rem .75rem;
  padding-left: 1.5rem;
  color: #495057;
  min-height: 38px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.user_ta {
  @extend .user_tt;
  color: #d32e2e;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.video_box {
  border: 0;
  display: inline-block;
  padding-left: 1.5rem;
}
.chips_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: .75rem;
}
.user_btna_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.user_btna {
  border: 2px solid #ccc;
  padding: 5px 10px;
  background: transparent;
  border-radius: 20px;
  outline: none !important;
  text-transform: capitalize;
  color: grey;
  font-size: 14px !important;
  margin-top: .5rem;
}
.user_btnaa {
  @extend .user_btna;
  width: auto;
  float: left;
  text-align: center;
}
.user_btaaa {
  width: auto;
  float: left;
  text-align: center;
  border: 2px solid #ccc;
  padding: 5px 10px;
  background: transparent;
  border-radius: 20px;
  color: grey;
  font-size: 12px;
}
.user_btnb {
  @extend .user_btna;
}
.jj {
  color: red;
}
.user_iconp {
  margin: 0;
  padding: 0;
}
.user_ya {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.btn_drop {
  background-color: red !important;
}
.max_height {
  max-height: 25px;
}
.word_brk {
  width: 80%;
  word-wrap: break-word;
  font-size: 16px !important;
  cursor: pointer;
}
.user_icona {
  font-size: 18px;
  color: $dark_blue !important;
}
.img_style_prev {
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.main_pic_box {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 50%;
  background-size: 100% 100%;
}
.camera_position {
  position: absolute;
  bottom: 0;
  left: 40%;
  font-size: 30px;
  color: $yellow;
}
.imgcls {
  width: 100%;
  border-radius: 50%;
  height: 150px;
}
.about_me_box {
  p {
    text-align: left;
    font-weight: 400;
    word-spacing: 1px;
    text-align: left;
    border-radius: .25rem;
    padding: .375rem .75rem;
    padding-left: 1.5rem;
    color: #495057;
    min-height: 38px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
}
.institution_brk {
  word-wrap: break-word;
  width: 200px;
}
/* getResume info icon */
.getreume_mainbox {
  text-align:  center;
}
.getreume_box {
  display: inline-block;
  position: relative;
  margin-top: .25rem;
  text-transform: capitalize;
}
.info_icon {
  position: absolute;
  top: 8px;
  right: -24px;
  cursor: pointer;
}
svg.pencil{
  cursor: pointer;
}
.tech_certificate_box {
  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
    span.tech_certificate_field {
      border: 2px solid #ccc;
      padding: 5px 10px;
      background: transparent;
      border-radius: 20px;
      outline: none !important;
      text-transform: capitalize;
      color: grey;
      font-size: 14px;
      // margin-top: .5rem;
    }
    svg.pencil{
      cursor: pointer;
    }
  }
}
.checkboxlabel {
  width: 100%;
  line-height: 30px;
  cursor: pointer;
}
@media (max-width: 576px) {
  .user_btna {
    border: 2px solid #ccc;
    background: transparent;
    border-radius: 5px;
    outline: none !important;
    text-transform: capitalize;
    color: grey;
    font-size: 14px !important;
  }
  .user_m {
    font-size: 14px;
  }
  .user_ya {
    font-size: 14px !important;
    font-weight: 400;
    text-transform: capitalize;
  }
  .tech_certificate_box {
    p {
      span.tech_certificate_field {
        border-radius: 5px;
      }
    }
  }
}
.showNoBtn {
  float: right;
  cursor: pointer;
  font-size: 20px;
  span {
    color: $light_blue;
  }
  a {
    color: $orange_color;
  }
}

// User Location on Profile 
.user-location-value{
  padding: 0;
  min-height: 25px;
} 

.field_pointer{
  cursor: pointer;
}

.field_placeholder{
 color: $grey !important;
 padding-left:0 !important;
}

.videoIcon{
  position: absolute;
  margin-left: 85%;
  cursor: pointer;
}
// Shortlist icons on User profile
.icon_text{
  font-size: 12px;
  margin: 0px 10px 0px 10px;
}
// back button image on User profile
.back_btn {
  position: absolute;
  left: 10px;
  top: -10px;
  display: flex;
  flex-direction: column;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  box-shadow: 0 0 6px rgba(12, 20, 61, 0.17);
}
.back_btn img {
  width: 20px;
}
// cross button image dialog
.modal_close_btn {
  position: absolute;
  color: black;
  font-size: 15px;
  top: 0px;
  right: 7px;
}
.modal_close_btn:hover {
  color: #ccc;
}
.shortlist_modal {
  flex: 0 0 auto;
  margin: 0;
  padding: 15px 40px 0px 40px !important;
}