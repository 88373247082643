@import "../../style/common/globl";
$orange_gradient: linear-gradient(90deg, #fdce06 0%, #ff6b17 100%);
$orange_gradient_hover: linear-gradient(90deg, #ff6b17 0%, #fdce06 100%);
$blue_gradient: linear-gradient(90deg, #0678c1 0%, #0b9fe1 100%);
$blue_gradient_hover: linear-gradient(90deg, #0b9fe1 0%, #0678c1 100%);
.chooseplans_main {
  margin-top: 61px;
  margin-bottom: 60px;
}
.chooseplans_inner {
  padding: 15px 0px;
}
.plans_title {
  border: 2px solid $black;
  border-radius: 4px;
  padding: 10px;
  background: $orange_gradient;
  margin-bottom: 15px;
}
.plans_title h3 {
  font-size: 20px;
  text-align: center;
  color: $black;
}
.plans_heading p {
  font-size: 16px;
  color: $black;
  text-align: center;
  margin-bottom: 15px;
}
.plans_details_list {
  border: 2px solid $black;
  border-radius: 4px;
}

.plans_details_inner table {
  width: 100%;
}
.plans_details_inner tr {
  height: 54px;
  border-bottom: 2px solid $black;
}
tr.border_bottom_zero {
  border-bottom: 0;
}

.plans_details_inner th {
  padding: 5px 5px;
  font-weight: 700;
  font-size: 14px;
  color: $black;
  text-align: center;
  border-right: 2px solid $black;
}
.plans_details_inner th span {
  display: block;
}
.plans_details_inner td {
  padding: 5px 5px;
  font-size: 14px;
  color: $black;
  text-align: center;
  border-right: 2px solid $black;
}
.plans_details_inner td:first-child {
  text-align: left;
}
.plans_details_inner th:last-child,
.plans_details_inner td:last-child {
  border-right: 0;
}

.plans_details_inner th.feature {
  width: 60%;
  text-align: center;
}
.plans_details_inner th.basic {
  width: 20%;
}
.plans_details_inner th.premium {
  width: 20%;
}

.plans_details_inner td span.text {
  font-size: 16px;
  font-weight: 700;
}
.plans_details_inner td span.icon {
  font-size: 20px;
}
.plans_details_inner td svg.available {
  color: #4abf37;
}
.plans_details_inner td svg.unavailable {
  color: #ee2323;
}
.buynowbtn {
  text-align: right;
  padding: 10px;
  button {
    font-size: 16px;
    color: $white;
    padding: 5px 10px;
    border-radius: 4px;
    letter-spacing: 0.5px;
    font-weight: 700;
    background: $blue_gradient;
    transition: all 0.5s ease-in-out;
    &:hover {
      background: $blue_gradient_hover;
      transition: all 0.5s ease-in-out;
    }
  }
}
.buynowbtn button:disabled {
  opacity: 0.65;
  background: #6C757D;
  cursor: not-allowed;
}
/* saarthi modal start */
.saarthi_modal_content {
  padding: 20px !important;
}
.saarthi_modal_content p {
  font-size: 16px;
  color: $black;
  font-weight: 700;
  margin-bottom: 10px;
}
.saarthi_modal_content label {
  font-size: 16px;
  color: $black;
  font-weight: 700;
  margin-bottom: 5px;
}
.saarthi_modal_content .form-control {
  text-transform: uppercase;
}
.saarthi_modal_content .buynowbtn {
  text-align: center;
  padding: 0;
}
/* saarthi modal close */

/* recruiter plan start */
.recruiterplan th.feature {
  width: 40%;
  text-align: center;
}
.recruiterplan th.gold {
  width: 20%;
  min-width: 80px;
}
.recruiterplan th.diamond {
  width: 20%;
  min-width: 80px;
}
.recruiterplan th.platinum {
  width: 20%;
  min-width: 80px;
}
.recruiterplan .buttonrow td {
  padding: 10px 0px;
}
.recruiterplan .buynowbtn {
  text-align: center;
  padding: 0;
  button {
    font-size: 14px;
    border: 1px solid $blue_color;
    padding: 5px 5px;
    letter-spacing: 0px;
    font-weight: 500;
  }
}
/* recruiter plan end */

/* recruiter plan amount start */
.recruiter_plan_main {
  margin-top: 61px;
  margin-bottom: 60px;
  h3 {
    font-size: 28px;
    color: $black;
    font-weight: 500;
    text-align: center;
    padding: 15px 0px;
  }
}
.recruiter_plan_main .MuiTab-root {
  font-size: 15px;
  color: $blue_color;
  min-width: 100px;
  width: 33.33%;
}
.recruiter_plan_main .MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  font-weight: 700;
}
.recruiter_plan_main .PrivateTabIndicator-colorPrimary-7 {
  background-color: transparent;
  background: linear-gradient(90deg, #ff6b17 0%, #fdce06 100%);
  height: 3px;
}
.plan_content {
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 15px;
}
.plan_content h2 {
  font-size: 20px;
  color: $black;
  font-weight: 700;
  margin-bottom: 10px;
}
.plan_content p {
  font-size: 16px;
  color: $black;
  margin-bottom: 20px;
}
.plan_content ul {
  list-style-type: none;
  border-bottom: 2px solid #ccc;
}
.plan_content ul li {
  font-size: 16px;
  color: $blue_color;
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
}
.plan_content ul li svg {
  margin-right: 5px;
  position: absolute;
  left: 0;
  top: 5px;
}
.plan_content h4 {
  font-size: 60px;
  color: $black;
  font-weight: 700;
  text-align: center;
  margin: 30px 0px;
  sup {
    font-size: 48%;
    top: -25px;
    right: 5px;
  }
  sub {
    font-size: 30%;
    bottom: 0;
    left: 5px;
  }
}
.chooseplanbtn {
  width: 100%;
  padding: 11px 11px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(90deg, #fdce06 0%, #ff6b17 100%);
  color: $white;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1px;
  outline: none !important;
  transition: all 0.5s ease-in-out;
  margin-bottom: 15px;
}
.chooseplanbtn:hover {
  background: linear-gradient(90deg, #ff6b17 0%, #fdce06 100%);
  transition: all 0.5s ease-in-out;
}
/* recruiter plan amount end */

/* payment modal start */
.MuiDialogContent-root.payment_inner:first-child {
  padding-top: 10px;
}
.MuiDialogContent-root.payment_inner {
  padding: 20px;
  padding-bottom: 20x;
}
.payment_content {
  span {
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 10px;
    display: block;
  }
  // h2 {
  //   font-size: 40px;
  //   line-height: 40px;
  //   text-align: center;
  //   background: linear-gradient(90deg, #eb621e, #fdce06);
  //   -webkit-background-clip: text;
  //   background-clip: text;
  //   color: transparent;
  //   font-weight: 700;
  //   margin-bottom: 10px;
  // }
  h3 {
    color: $black;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    color: $grey;
    text-align: center;
    margin-bottom: 10px;
    width: 190px;
  }
  button {
    padding: 14px 14px;
    background: linear-gradient(90deg, #0678c1, #0b9fe1);
    font-size: 14px;
    border-radius: 4px;
    line-height: 14px;
    color: $white;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    &:hover {
      background: linear-gradient(90deg, #0b9fe1, #0678c1);
    }
  }
  &.success {
    span {
      color: #4abf37;
    }
  }
  &.failed {
    span {
      color: #ee2323;
    }
  }
}
/* payment modal end */
/* css for price-text */
.delete-price{
  color: gray;
}
.current-price{
  color: black;
  font-size: 18px;
}
/* Desktop ribbon start from here */
.ribbon{
  display: none;
}
.inner-ribbon {
  display: block;
  width: calc(100% + 0px);
  height: 40px;
  line-height: 36px;
  text-align: center;
  background: linear-gradient(
    90deg, #0678c1 0%, #0b9fe1 100%);
  position: relative;
  top: 2px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  border-radius: 3px;
  border: 2px solid #1e1f21;
}
.inner-ribbon:before,
.inner-ribbon:after {
  content: "";
  position: absolute;
}
/* Desktop ribbon end */
/* Mobile-view ribbon start from here */
.box {
  position: relative;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}
.ribbon-side {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon-side::before,
.ribbon-side::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon-side span {
  position: absolute;
  display: block;
  width: 266px;
  padding: 4px 0;
  background: linear-gradient(90deg, #0678c1 0%, #0b9fe1 100%);
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font-size: 9px;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 54px;
}
.ribbon-top-left::after {
  bottom: 54px;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
@media (max-width: 767px) {
  .display-none{
    display: none;
  }
  .ribbon{
    display: block;
  }
}
/* Mobile-view ribbon end */
/* css for premium membership modal start from here */    
.premium-modal .MuiDialogContent-root {
  padding: 20px;
  color: $black;
  font-weight: 700;
  font-size: 20px;
  max-width: 400px;
}
.premium-member-container{
  width: 100%;
  border: none;
  padding: 10px;
  max-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
}
.premium-member-image p {
  margin-top: 4px;
  font-size: 22px;
  color: $yellow;
  letter-spacing: 1px;
}
.congrats-message p{
  color: $dark_blue;
  font-size: 18px;
  letter-spacing: 1px;
}  
.close-icon{
  position: absolute;
  right: 10px;
  top: 5px;
}
.cross-image{
  height: 22px;
  width: 22px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .premium-member-image p {
    font-size: 16px;
  }
  .congrats-message p {
    font-size: 14px;
  }
  .cross-image {
    height: 20px;
    width: 20px;
  }
}
/* css for premium membership modal end */  