@import "../../../style/common/globl";

.employerBasicDetails .form-control:disabled, 
.employerBasicDetails .form-control[readonly] {
  background-color: #e9ecef;
}
.employerBasicDetails .form-control:focus {
  box-shadow: none;
}
.iconn_pro{
  font-size:20px;
  color:$dark_blue;
}

.llbl{
  color:$dark_blue;
  text-transform:capitalize;
  font-size:16px;
  font-weight:500;
  padding-bottom: 8px;
}

.st{
  margin-top:80px!important;
  margin-bottom: 75px !important;
}

.txt{
  padding:0;
  margin:0;
  font-size:14px;
  text-align:left;
}

.iconn_proo {
  font-size:16px;
  color:$dark_blue;
}

.word_brkk{
  word-wrap:break-word !important;
  width:150px !important;
  max-width:150px;
  font-size:14px;
  cursor: pointer;
}

textarea.form-control.aboutmecompany {
  min-height: 38px;
  height: 280px;
  resize: none;
}

.district-text{
  color: black !important;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 400 !important;
}

.verification_type_box {
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  p.hiringfor {
    color: black !important;
  }
  label {
    font-size: 16px;
    color: black !important;
    text-transform: capitalize;
    font-weight: 400 !important;
    padding-bottom: 8px;
  }
  input[type="radio"]:checked + label {
    height: auto;
    display: inline-block;
    background: transparent;
    color: $black;
    width: auto;
  }
}

.hiring_type_box {
  display: flex;
  align-items: center;
  .margin-right {
    margin-right: 15px;
  }
  label {
    margin-left: 5px;
    font-size: 16px;
  }
}

.company_verfification_heading {
  color: #0678c1;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 20px;
  text-align: center;
}

.verification_details_box select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-right: 2rem;
  padding-right: 2rem;
}
.common_btn2 {
  padding: 2% 10% 2% 10% !important;
}

.employer_profile_box {
  text-align: left;
}
.emp_profile_label {
  color: black !important;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400 !important;
  margin-bottom: 5px;
  position: relative;
  padding-left: 25px;
}
.hiringfor {
  color: black !important;
  font-size: 14px;
  margin-bottom: 10px;
}
.emp_profile_label .iconn_pro {
  font-size: 20px;
  color: $dark_blue;
  position: absolute;
  left: 0;
  top: -1px;
}
.emp_profile_label font {
  margin-left: 3px;
}
.employer_profile_box .form-control:focus {
  border: 1px solid #0b9fe1;
  box-shadow: none;
}
.employer_profile_box .form-control:read-only {
  background-color: #f0f0f0;
}
.employer_profile_box input[type="radio"].readOnly {
  pointer-events: none;
}
.employer_profile_box input[type="radio"].readOnly + label {
  pointer-events: none;
}
.hiringInner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 16px;
  .company_radio_box {
    margin-right: 20px;
  }
}
.hiringInner.readOnly {
  border-color: #ccc;
  background-color: #f0f0f0;
}
.form_radio_box {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.employer_profile_box .form-control-radio {
  width: auto !important;
  display: inline !important;
  height: auto;
  padding: 0 !important;
}
input.form-control-radio[type="radio"] + label {
  padding-left: 10px;
  line-height: initial;
  margin-bottom: 0;
}
input.form-control-radio[type="radio"]:checked + label {
  background: unset;
  display: inline-block;
  width: auto;
  height: auto;
  padding-left: 10px;
  line-height: initial;
}
// modal css start
.company_verification_modal {
  padding-bottom: 20px;
}
.company_verification_modal .st {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.company_verification_modal .p_bottom {
  padding-bottom: 0px !important;
}
.company_verification_modal .px-3 {
  padding-left: 0rem!important;
  padding-right: 0rem!important;
}
.company_verification_modal .alert-toast {
  position: absolute;
  top: 10px;
  z-index: 999;
  margin: 0 10px;
  left: 0;
  right: 0;
}
// modal css end
.verification_details_box .form-group .form-control[readonly] {
  background-color: #e9ecef;
}
.verification_details_box .form-group .form-control:focus {
  box-shadow: none;
}
.editOrganizationType {
  float: right;
  font-size: 20px;
  color: $orange_color;
}
.common_btn:disabled {
  background: transparent;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
  color: #495057;
}