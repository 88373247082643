.main-jobdetails-container{
	overflow-y: scroll;
	max-height: 400px;
	&::-webkit-scrollbar {
		width: 4px;
		border-radius: 4px;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		border-radius: 0px;
		background-color: #ffffff;
	}
	&::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
		box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		border-radius: 0px;
		background-color: #0678c1;
	}
}
// css for employer contact icon start from here
.phone-icon{
	padding: 0;
    margin: 0;
    font-size: 22px;
    color: #0b9fe1;
    margin-right: 5px;
    width: 16px;
    height: 16px;
}
// css for employer contact icon end
.job-details-text{
	word-break: break-all;
}