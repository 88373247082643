.info_header {
    text-align: center;
    padding: 0 30px;
}
.info_header p {
    margin-top: 8px;
    padding: 0 10px;
    color : #999999;
    font-size: 13px;
    font-weight: 400;
}
.info_body {
    margin-top: 30px;
    overflow-y: scroll;
    height: 350px;
    &::-webkit-scrollbar {
        width: 8px;
        border-radius: 0px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
        border-radius: 0px;
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
        border-radius: 0px;
        background-color: #0678c1;
      }
}
.info_body .info_card{
    padding: 5px;
    display: flex;
}
.info_card .check_icon {
    font-size: large;
    color: #0678c1;
}
.info_card .info_content {
    border-bottom: 2px solid #999999;
    margin-left: 10px;
    padding-bottom: 5px;
    flex-basis: 90%;
}
.info_content p {
    font-size: large;
    color: black;
}