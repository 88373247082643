.uur,
.tbbr {
    cursor: pointer;
}

.tbbr {
    font-size: 13px;
}

.tbbrr,
.tbbr {
    margin-top: 3px;
}
