@import "../../../style/common/globl";

.main_job {
  width: 100%;
  position: relative;
}
.main_job_container {
  padding-bottom: 64px;
}

.Job_ta {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  word-wrap: break-word;
}

.Job_ttb {
  @extend .Job_ta;
  font-weight: 400;
  word-wrap: break-word;
}
.job_tb {
  @extend .Job_ta;
  font-size: 10 !important;
  font-weight: 400;
}

.job_tc {
  @extend .Job_ta;
  font-size: 10 !important;
  font-weight: 400;
}

._job_ua {
  padding: 0;
  margin: 0;
  font-size: 22px;
  color: $light_blue;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

._job_ub {
  color: $dark_blue;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.ca {
  color: $black;
  font-size: 14px;
  font-weight: 400;
  padding-left: 30px;
}

.ch {
  @extend .ca;
  font-size: 14px;
  text-transform: none;
  &::first-letter {
    text-transform: uppercase;
  }
}

.btn_va {
  text-transform: capitalize;
  padding: 6px;
  background-color: #ee2323;
  color: white;
  border-radius: 20px;
  font-weight: 500;
  outline: none !important;
  border: none !important;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_vb {
  @extend .btn_va;
  background-color: #33a8ff;
}

.btn_vc {
  @extend .btn_va;
  background-color: #4abf37;
}

.cross_icon {
  font-size: 24px;
  color: white;
}

.box_ia {
  border-radius: 50%;
  background-color: white;
}

.box_ib {
  @extend .box_ia;
  background-color: white;
}

.job_pa {
  color: #33a8ff;
  font-size: 19.5px !important;
}
// css for disabled-icon
.disabled-icon {
  color: #ccc;
  font-size: 19.5px !important;
}
.job_pb {
  @extend .job_pa;
  color: #4abf37;
}

.skiils_width {
  word-wrap: break-word;
  width: auto;
}

.jobResponse {
  margin-left: 5px;
}


/* joblist page start */
.joblistview {
  margin-top: 61px;
  margin-bottom: 53px;
}
.joblistheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
  margin-left:30%;
  p {
    color: $black;
    font-size: 16px;
    margin-right: 5px;
  }
  button.jobviewbtn {
    background-color: $blue_color_hover;
    font-size: 16px;
    color: $white;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px solid $blue_color_hover;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background-color: $white;
      color: $blue_color_hover;
    }
  }
  button.filter_sort_btn {
    margin: 0px 5px;
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      padding: 5px;
      &:active {
        background-color: rgba(0,0,0,.07)!important;
      }
      &:hover {
        background-color: rgba(0,0,0,.05)!important;
      }
    }
  }
}
p.counts {
  color: $black;
  font-size: 16px;
  position: absolute;
  top: -40px;
  left: 0px;
}
.joblistviewbox {
  border-radius: 4px;
  border: 1px solid $grey;
  position: relative;
  .joblistview_content {
    position: relative;
    padding: 10px;
    border-bottom: 1px solid $grey;
    &:last-child {
      border-bottom: 0px solid $grey;
    }
    .verification {
      position: absolute;
      right: 12px;
      top: 5px;
      color: #4abf37;
      font-size: 20px;
    }
    .job_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .joblogo {
      width: 25%;
      padding-right: 15px;
      img {
        width: 90px;
        height: 90px;
        display: block;
        margin: 0 auto;
        overflow: hidden;
      }
    }
    .job_details {
      width: 75%;
      padding-left: 15px;
      h3 {
        font-size: 16px;
        color: $black;
        font-weight: 700;
      }
      h4 {
        font-size: 16px;
        color: $blue_color;
        letter-spacing: 0.5px;
        font-weight: 700;
      }
      p {
        font-size: 16px;
        color: $black;
        font-weight: 500;
        span {
          font-weight: 700;
        }
      }
    }
    .viewdeatilsbtn {
      width: 100%;
      text-align: center;
      position: relative;
      button.viewdetails {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        color: $blue_color;
        margin-top: 5px;
        &:hover {
          color: $blue_color_hover;
          text-decoration: underline;
        }
        svg {
          pointer-events: none;
        }
      }
      button.moreoptns {
        color: $black;
        font-size: 20px;
        &:hover {
          color: $blue_color;
        }
        svg {
          pointer-events: none;
        }
      }
    }
  }
}

.dropdownbox {
  position: absolute;
  right: 0;
  bottom: 0;
}
.dropdownmenu {
  padding: 0px;
  min-width: 130px;
  position: absolute;
  bottom: 0px;
  right: 0;
  background: #ffff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16), 0 2px 8px 0 rgba(0,0,0,0.12);
  transition: all 0.25s ease 0s;
}
.dropdownmenu ul {
  list-style-type: none;
}
.dropdownmenu ul li span {
  width: auto;
  color: $black;
  font-size: 15px;
  line-height: 15px;
  padding: 12px 10px;
  white-space: nowrap;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-decoration: none;
  background-color: transparent;
  transition: all 0.25s ease 0s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dropdownmenu ul li span:hover {
  text-decoration: none;
  background: #ebebeb;
  transition: all 0.25s ease 0s;
}

.dropdown-content {
  opacity:0;
  transform-origin: right bottom 0px;
  -webkit-transform-origin: right bottom 0px;
  transform: scale(0);
  -webkit-transform: scale(0);
}
.show {
  opacity:1;
  transform-origin: right bottom 0px;
  -webkit-transform-origin: right bottom 0px;
  transform: scale(1);
  -webkit-transform: scale(1);
}
.option_menu{
  .view_menu_icons {
    margin-right: 10px;
    font-size: 20px;
  }
  .notapply {
    color: #ee2323;
  }
  .applylater {
    color: #33a8ff;
  }
  .applynow {
    color: #4abf37;
  }
  .disabled-appybutton{
    color: #ccc;
  }
}
button.viewdetails {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  color: $blue_color;
  margin-top: 5px;
  &:hover {
    color: $blue_color_hover;
    text-decoration: underline;
  }
}
.jobdetailsfield {
  margin-bottom: 10px;
  min-height: 37px;
}
.jd {
  padding-left: 0px;
}
/* joblist page end */

.public_job_share {
  max-width: 33.33%;
  margin: 0 auto;
  padding: 30px 15px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
}
.job_share {
  padding-top: 70px;
}
@media (max-width: 768px) {
  .public_job_share {
    max-width: 100%;
  }
  .joblistheader {
    margin-left: 37%;
  }
}
/* tooltip css start*/
.__react_component_tooltip {
  padding: 4px 8px !important;
  background: #58595b !important;
  &.place-top::after {
    border-top-color: #58595b !important;
  }
  &.place-left::after {
    border-left-color: #58595b !important;
  }
}
/* tooltip css end*/
/* mouse-pointer event*/
.mouse-pointer{
  pointer-events: none;
}
// css for disabled-button
.disabled-button {
  text-transform: capitalize;
  padding: 6px;
  background-color: #ccc;
  color: white;
  border-radius: 20px;
  font-weight: 500;
  outline: none !important;
  border: none !important;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.job_Location{
max-width: 258px;
}
// css for Job filter button on job view screen
.job_filter_icon {
	cursor: pointer;
  }
  .job_filter_modal{
	height: 100%;
  }
  .job_filter_title{
	color:$blue_color;
	text-transform:capitalize;
	font-size:14px;
	font-weight:bold;
	padding-bottom: 5px;
  }
.filter_font{
    font-size: 30px !important;
}