@import "../../../style/common/notification.scss";
@import "../../../style/common/globl.scss";

.user-card-image {
    width: 80px;
    height: 80px;
}

.user-name {
    font-size: 16px !important;
    font-weight: 400;
    word-wrap: break-word;
}

.user-card-main {
    background-color: $light-pink;
    display: flex;
    justify-content: space-between;
}

.card-fields {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: $grey;
    word-break: break-word;
}

.filter-icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.filter-title {
    color: $blue-color;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
    word-break: break-word;
}

.filter-options {
    color: $grey;
    font-size: 15px;
}

.no-record {
    font-size: 20px;
    padding-top: 80px;
    padding-bottom: 80px;
    color: $blue-color;
    text-transform: capitalize;
    font-weight: bold;
    text-align: center;
}

.modal-close-btn {
    position: absolute;
    color: $black;
    font-size: 15px;
    top: 0px;
    right: 7px;
}

.modal_close_btn:hover {
    color: $light_grey;
}

.load-more {
    border: 1px solid $grey;
    border-top: none;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 3px 4px rgba(12, 20, 61, 0.17);

    &:hover {
        color: $blue-color;
        cursor: pointer;
    }

    p {
        font-size: 18px;
    }
}