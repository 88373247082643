/* create resume screen 1st screen start*/
.resume {
    max-width: 300px;
    margin: auto;
}
.container_btn {
    margin: 20px 0px;
    display:flex;
    justify-content: space-between;
}
.template {
    max-width: 320px;
    padding: 20px;
    height: 320px;
    border: 2px solid #cccccc;
    border-radius: 4px;
}
.template_inner {
    height: 100%;
}
.template_inner img {
    width: 100%;
    height: 100%;
}
.container_btn label {
    line-height: 30px;
    height: 30px;
    padding: 0 8px;
    border-radius: 4px;
    background:linear-gradient(90deg, #0b9fe1 0%, #0678c1 100%);
    color: #ffffff;
}
.video_frame {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
/* create resume screen 1st screen ends*/

/* Resume recorder screen start*/
.resume_container {
    position: relative;
}
.switch_camera_icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: -20px;
}
.slides {display: none;
    animation: fade 1s linear;
}
@keyframes fade{
  from{
    opacity:0.1;
  }
  to{
    opacity: 1;
  }
}
.resume_title {
    text-align: center;
    margin-bottom: 5px;
}
.resume_Ques {
    text-align: center;
    margin-bottom: 5px;
    min-height: 44px
}
.resume_Ques p {
    font-size: 12px;
}
.video_recorder {
    display: flex;
    justify-content: center;
}
.recorder_inner {
    width: 100%;
    max-width: 425px;
    position: relative;
}
.recorder_inner p {
    position: absolute;
    right: 0;
    top: -25px
}
.video_player {
    width: 100%;
    height: calc(100vh - 310px);
    min-height: 300px;
    object-fit: cover;
}
.video_controls {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
.control_btn {
    height: 30px;
    padding: 0 8px;
    border-radius: 4px;
    background:linear-gradient(90deg, #0b9fe1 0%, #0678c1 100%);
    color: #ffffff;
}
.control_icons {
    width: 30px;
    height: 30px
}
// recording blink icon
.blink {
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }
/* Resume recorder screen ends*/
.retake_btn {
    margin-right: 20px;
}