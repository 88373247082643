 @import "../../../style/common/globl";
.setting_op {
     padding:0;
     margin:0;
    width: 48%;
    border: 1px solid $dark_blue;
    outline: none;
    // padding: 2% 2% 2% 2%;
    color: $dark_blue;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .setting_op:hover{
    @extend .setting_op;
     background-color:$dark_blue;
     color:#fff;
      }

.setting_pp {
  @extend .setting_op;
  width: 100%;
  border: 1px solid $dark_blue;
}
.setting_pp:hover {
  @extend .setting_op;
  background-color: $dark_blue;
  color: #fff;
}
// Back buttion on settings screen
.back_icon {
  font-size: 20px;
}
.back_icon:hover {
  cursor: pointer;
}

    .bg_cll{
      background: linear-gradient(
        90deg,
        rgba(11, 159, 225, 1) 0%,
        rgba(6, 120, 193, 1) 100%,
      );
      color:#fff;
    }
/* css for premium membership modal start from here */    
.premium-modal .MuiDialogContent-root {
  padding: 20px;
  color: $black;
  font-weight: 700;
  font-size: 20px;
  max-width: 400px;
}
.premium-member-container{
  width: 100%;
  border: none;
  padding: 10px;
  max-width: 500px;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
}
.premium-member-image p {
  margin-top: 4px;
  font-size: 22px;
  color: $yellow;
  letter-spacing: 1px;
}
.congrats-message p{
  color: $dark_blue;
  font-size: 18px;
  letter-spacing: 1px;
}  
.close-icon{
  position: absolute;
  right: 10px;
  top: 5px;
}
.cross-image{
  height: 22px;
  width: 22px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .premium-member-image p {
    font-size: 16px;
  }
  .congrats-message p {
    font-size: 14px;
  }
  .cross-image {
    height: 20px;
    width: 20px;
  }
}
/* css for premium membership modal end */
/* css for whatsapp modal start from here */
.whatsapp-main-heading{
  color: #29A71A;
  font-size: 20px;
  margin-top: 5px;
}
.whatsapp-text{
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
}
.whatsapp-button {
  cursor: pointer;
  background: linear-gradient(90deg, rgba(11, 159, 225, 1) 1%, rgba(6, 120, 193, 1) 100%);
  color: white;
  border-radius: 4px;
  padding: 6px  30px 6px 30px;
  transition: all 0.35s;
  min-width:40px;
  margin-top: 5px;
  font-size: 18px;
}
.whatsapp-button:hover {
  background: linear-gradient(90deg, rgba(11, 159, 225, 1) 1%, rgba(6, 120, 193, 1) 100%);
  transition: all 0.35s;
}
/* css for toggle button start from here */
 
.whatsapp-notification-button[type="checkbox"] {
  position: relative;
  width: 60px;
  height: 30px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(255, 0, 0, 0.2);
  transition: 0.7s;
  margin-top: 10px;
}

.whatsapp-notification-button:checked[type="checkbox"] {
  background: linear-gradient(90deg, rgba(6, 120, 193, 1) 1%, rgba(11, 159, 225, 1) 100%);
}

.whatsapp-notification-button[type="checkbox"]:before {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  top: 4px;
  left: 4px;
  background: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: .5s;
}

.whatsapp-notification-button:checked[type="checkbox"]:before {
  left: 35px;
}
/* css for toggle button end */