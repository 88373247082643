@import "../../../style/common/globl.scss";

.iconn_pro {
  font-size: 20px;
  color: $dark_blue;
}

.llbl {
  color: black !important;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 400 !important;
}
.cc {
  color: $dark_blue;
  font-size: 18px;
  font-weight: 600;
}

.fw {
  width: 100%;
}

.lw {
  width: 100%;
}

.detail_btn_cca {
  width: 33.33%;
  min-height: 30px;
  font-size: 12px;
  margin-right: 10px !important;
  margin-left: 2px !important;
}

.detail_btn_ccab {
  width: 25%;
  max-width: 25%;
  min-width: 25%;
  min-height: 30px;
  font-size: 12px;
  border: 1px solid #0678c1;
  color: #0678c1;
  text-align: center;
}

.hidd {
  display: hidden;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 2px;
}

.animatedjd_modal .MuiDialogContent-root {
  padding: 20px;
}
.animatedjd_modal .MuiDialogContent-root p {
  color: $black;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 20px;
}
.animatedjd_btns {
  text-align: center;
  margin-top: 30px;
}
.animatedjd_btns button {
  font-size: 16px;
  line-height: 16px;
  min-width: 100px;
  padding: 10px 4px;
  border-radius: 4px;
  margin: 0px 10px;
}
.animatedjd_btns .no {
  background-color: $blue_color;
  border-color: $blue_color;
  color: $white;
}
.animatedjd_btns .no:hover {
  border-color: $blue_color_hover;
  background-color: $blue_color_hover;
}
.animatedjd_btns .yes {
  background-color: $orange_color;
  border-color: $orange_color;
  color: $white;
}
.animatedjd_btns .yes:hover {
  background-color: #ff5733;
  border-color: #ff5733;
}
/* css for employer contact checkbox start from here */
.call-me-text{
  text-align: left;
  font-size: 13px;
  margin-top: 2px;
}
.contact-checkbox {
  position: relative;
  display: block;
  padding-left: 1.36rem;
}
/* css for employer contact checkbox end */
/* css for logo hover tooltip start from here */
.tooltip-container{
  position: absolute;
  font-weight: bold;
  background-color: #444;
  width: 10vw;
  margin: 8px auto;
  text-align: center;
  border-radius: 4px;
  padding: 10px;
  bottom: -130px;
  left: 0;
  right: 0;
}
.tooltip-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 8px solid #444;
  transform: translate(-50%, calc(-100% - -2px));
}
.client-profile{
  height: 70px;
  width: 70px;
}
.company-profile{
  height: 70px;
  width: 70px;
  border-radius: 50px;
}
.hiring-for-text {
  color: white !important;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 400 !important;
}
.position-relative{
  position: relative!important;
}
/* css for logo hover tooltip end */

.jobPostForm .form-control.readOnly {
  background-color: #e9ecef;
}
.jobPostForm .form-control:focus {
  box-shadow: none;
}
