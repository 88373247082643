@import "../../../style/common/globl";
@import "../../../style/common/btn";


.ya {
  font-size: 15px;
  font-weight: 500;
  color: $dark_blue;
}

.yb {
  @extend .ya;
  font-size: 12px;
  font-weight: 400;
  color: $light_blue;
}

.btn_bdr {
  width: 100%;
  background-color: #ffff;
  box-shadow: 2px 4px 8px 4px #ddd;
}

.in{
  color:$dark_blue;
  font-weight:600;
  font-size:16px;
}

.in_a{
       @extend .in;
       font-size:14px;
       padding:0;
       margin:0;
       font-weight:400;
}
input[type="checkbox"]:checked + label  {
  .filter-green{
    filter: invert(29%) sepia(87%) saturate(1300%) hue-rotate(180deg) brightness(100%) contrast(95%);
  }
}


.job_cat{
  height:450px;
  overflow:scroll;
}
.fixedHeight
{
  max-height: 100vh;
}
// body{
//   overflow: hidden;
// }