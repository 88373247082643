@import "../../../style/common/notification.scss";
.MuiBox-root-10 {
  padding: 0;
}

.main_notification {
  width: 100%;
  background-color: #ffefe5;
}

.noti_user {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.btn_noti {
  padding: 4px;
  border: 1px solid $light_blue;
  text-transform: capitalize;
  font-weight: 400;
  color: $light_blue;
}

.user_ua,
.user_ub,
.user_uc {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.user_nme {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.post_time {
  font-size: 10px;
  color: grey;
  text-align: center;
}

.fnt_icon {
  font-size: 15px !important;
  margin-right: 5px;
  margin-top: -3px;
}

.call-icon {
  cursor: pointer;
  font-size: 20px;
  color:$orange_color;
  margin-top: 10px;
  a {
    color:$orange_color;
  }
  a:not([href]):not([class]) {
    color:$orange_color;
  }
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

.contact_color span {
  color: $light_blue;
}