@media (max-width: 576px) {
  .otp_ra {
    display: none;
  }

  .otp_rb {
    width: 100%;
  }

  .otp_tc {
    padding: 0;
    margin: 0;
    font-size: 14px !important;
  }
}
