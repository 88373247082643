@import '../../../../node_modules/@fortawesome/fontawesome-free/css/all.css';
$black: #1E1F21;
$white:  #ffffff;

$blue_color: #0678c1;
$blue_color_hover: #0b9fe1;

$orange_color: #eb621e;
$orange_color_hover: #fdce06;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    border-radius: 0px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    border-radius: 0px;
    background-color: $blue_color;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
label {
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

a {
  outline: medium none !important;
  text-decoration: none;
  transition: all 0.35s ease 0s;
}

a:hover {
  text-decoration: none;
}
a:not([href]):not([class]) {
  color: #666;
}
a:not([href]):not([class]):hover {
  color: $blue_color;
}
a:focus {
  text-decoration: none;
}

button {
  transition: all 0.35s ease 0s;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}

button:focus {
  outline: 0px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
  margin: 0;
}

ul {
  list-style-type: none;
}

/* header start */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: $white;
  padding: 15px 15px;
  transition: all 0.35s ease 0s;
  box-shadow: 0 0 15px #0c143d2b;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.nav_lft {
  width: auto;
}

.logo {
  width: auto;
  a {
    display: inline-block;
    cursor: pointer;
  }
  img.logoicon {
    width: 40px;
  }
  img.logoname {
    width: 170px;
  }
}

.header_menu {
  display: flex;
  align-items: center;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  li {
    padding: 0;
    margin: 0px 5px;
  }
  a {
    display: inline-block;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 600;
    color: #666;
    text-decoration: none;
    cursor: pointer;
  }
  a:hover {
    color: $blue_color_hover;
  }
  a.active {
    color: $blue_color;
    background-color: transparent;
  }
  a.active:hover {
    color: $blue_color_hover;
  }
  a.activeblog {
    color: $blue_color;
    background-color: transparent;
  }
  a.activeblog:hover {
    color: $blue_color_hover;
  }
}

.nav_right {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  li {
    display: inline-block;
    margin: 0px 5px;
  }
  a {
    display: inline-block;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 600;
    color: #666;
    text-decoration: none;
  }
  a:hover {
    color: $blue_color;
  }
  .signupbtn {
    border: 1px solid $blue_color;
    background-color: $blue_color;
    color: $white;
    border-radius: 4px;
    padding: 5px 25px;
  }
  .signupbtn:hover {
    background-color: $white;
    color: $blue_color;
  }
}

.languagebox {
  background-color: $white;
  border-radius: 20px;
  padding: 4.5px 5px;
  border-radius: 4px;
  border: 1px solid $blue_color;
  min-width: 101px;
  margin-left: 10px;
  select {
    border: 0;
    background: transparent;
    color: #057abf;
    font-size: 14px;
    padding: 2px 10px;
    height: auto;
    option {
      padding: 10px;
    }
  }
  :focus-visible {
    outline: 0;
  }
  :focus {
    box-shadow: none;
    color: #057abf;
  }
}
/* header end */

/* footer start */
.footer {
  background-color: #202020;
  padding: 0px 0px 0px;
  color: #999;
}
.footer_social h3 {
  color: $white;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.socialicon ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  margin: 0 auto;
}

.socialicon ul li {
  margin: 0px 10px;
}

.socialicon ul li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid $white;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialicon ul li a.facebook {
  background-color: #4a6d9d;
  border-color: #4a6d9d;
}

.socialicon ul li a.twitter {
  background-color: #3bc1ed;
  border-color: #3bc1ed;
}

.socialicon ul li a.linkedin {
  background-color: #0b7bb5;
  border-color: #0b7bb5;
}

.socialicon ul li a.youtube {
  background-color: #CD351E;
  border-color: #CD351E;
}

.socialicon ul li a.instagram {
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  border-color: #dc2743;
}

.socialicon ul li a.facebook:hover {
  box-shadow: 0px 4px 10px #4a6d9d;
}

.socialicon ul li a.twitter:hover {
  box-shadow: 0px 4px 10px #3bc1ed;
}

.socialicon ul li a.linkedin:hover {
  box-shadow: 0px 4px 10px #0b7bb5;
}

.socialicon ul li a.youtube:hover {
  box-shadow: 0px 4px 10px #CD351E;
}

.socialicon ul li a.instagram:hover {
  box-shadow: 0px 4px 10px #f09433; 
}

.f_inner {
  padding: 80px 0px;
}

.footer_logo img{
  width: 300px;
  display: block;
  margin-right: auto;
  margin-left: -10px;
  margin-bottom: 45px;
}

.about h3, .footer_menu h3, .contact_form_heading h3 {
  color: $white;
  font-size: 22px;
  margin-bottom: 20px;
}
.about p {
  font-size: 18px;
  color: $white;
}

.footer_menu h3 {
  text-align: center;
}

.fm-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0px 0px;
  margin-top: 12px;
}

.footer_menu {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  li {
    padding: 0;
    margin-bottom: 10px;
  }
  a {
    color: #aaa !important;
    text-decoration: none;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  a::before {
    content: '‒' !important;
    margin-right: 10px;
  }
  a:hover {
    transform: translate(10px, 0px);
    color: $orange_color_hover !important;
  }
  a.active {
    background-color: transparent;
    color: $orange_color_hover !important;
  }
  a.activeblog {
    background-color: transparent;
    color: $orange_color_hover !important;
  }
}
.contact_form .form-control {
  font-size: 16px;
  color: $black;
  box-shadow: none;
  border: 0;
  font-weight: 500
}
.contact_form textarea {
  resize: none;
}
.contact_form .form-control::placeholder {
  color: #948a99;
}
.contact_form .form-control:focus {
  outline: 0;
}

.f_bottom {
  background-color: #0e0e0e;
  padding: 15px 0px;
}

.f_bottom p {
  font-size: 14px;
  text-align: center;
}
/* footer end */

.landing_main {
  margin-top: 71px;
  background: url(../../../assets/images/homepage/Website-banner-1920-x-720.png) no-repeat center center;
  background-size: cover;
  position: relative;
}

.landing_inner {
  text-align: center;
  height: 90vh;
  position: relative;
}

.landing_inner h1 {
  color: $white;
  font-weight: 700;
  font-size: 44px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 15px;
}
.landing_inner p {
  color: $white;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.landing_inner a.scrollbtn {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  color: $white;
  background-color: transparent;
  border: 2px solid $blue_color;
  display: inline-block;
  margin: 0 auto;
  width: 40px;
  height: 60px;
  vertical-align: middle;
  padding: 18px 0px;
  border-radius: 20px;
  animation: mover 1s infinite alternate;
  cursor: pointer;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

.landing_inner a.scrollbtn img {
  width: 30px;
  margin: 0 auto;
}

.jobexplorer {
  padding: 50px 0px;
  background-color: $white;
}

.je_inner {
  padding: 0px 100px;
}
.je_lft, .je_ryt {
  text-align: center;
}

.je_content {
  background-color: $white;
  box-shadow: 0 0 15px #0c143d2b;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.je_inner h2, .work_inner h2, .blogs_inner h2, .faq_inner h2 {
  font-size: 38px;
  line-height: 38px;
  font-weight: 700;
  color: $black;
  margin-bottom: 50px;
  text-align: center;
}

.je_inner h2 span, .work_inner h2 span, .faq_inner h2 span{
  color: $blue_color;
}

.je_inner h3{
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
  text-align: center;
}

.je_content a.custombtn {
  padding-left: 0;
  padding-right: 0;
  min-width: 160px;
  max-width: 165px;
}

.je_content img.img_job {
  width: 100px;
  height: auto;
}

a.custombtn, button.custombtn {
  line-height: 15px;
  font-weight: 700;
  color: $white;
  border-radius: 4px;
  padding: 13px 27px;
  letter-spacing: 2px;
  border: 2px solid $blue_color;
  background-color: $blue_color;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  border: none;
  color: #fff;
  margin: 0 0 5px;
  font-size: 15px;
}
a.custombtn:hover, button.custombtn:hover {
  border-color: $blue_color_hover;
  background-color: $blue_color_hover;
}

a.custombtn_orange {
  background-color: $orange_color;
  border-color: $orange_color;
}
a.custombtn_orange:hover {
  // background-color: $orange_color_hover;
  // border-color: $orange_color_hover;
  background-color: #ff5733;
  border-color: #ff5733;
}
/* work start */
.work, .blogs {
  padding: 50px 0px;
  background-color: #F6F6F6;
}

.work {
  background: url(../../../assets/images/homepage/workbg3.png) no-repeat;
  // background-size: cover;
  background-size: 40% 100%;
  background-position: right;
}

.wi_content_box {
  background-color: $white;
  padding: 30px;
  border: 1px solid $white;
  box-shadow: 0 0px 20px 0 rgba(235,98,30,0.3);
  border-radius: 50%;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.work_content {
  text-align: center;
  margin: 0 auto;
}

.marginbtm {
  margin-bottom: 30px;
}

.work_content img {
  margin: 0 auto;
  margin-bottom: 15px;
  width: 80px;
}

.work_content h3 {
  font-size: 20px;
  color: $orange_color;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
}

.videobox {
  width: 100%;
  background-color: $white;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.1);
}
/* work end */

/* blogs start */
.blogs {
  background: url(../../../assets/images/homepage/team-work.jpg) no-repeat center center;
  background-size: cover;
  padding: 50px 0px;
  position: relative;
}

.blogs_overlay {
  filter: brightness(50%) contrast(100%) saturate(100%) blur(0) hue-rotate(0deg);
  background-color: transparent;
  // background-image: linear-gradient(180deg,#303AF7 0%,#443088 100%);
  background-image: linear-gradient(180deg, $blue_color 0%, $blue_color_hover 100%);
  opacity: .7;
  transition: background 0.3s,border-radius 0.3s,opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.blogs_inner {
  position: relative;
}

.blogs_inner h2 {
  color: $white;
  margin-bottom: 20px;
}

.slider_inner {
  padding: 15px;
  padding-top: 30px;
  font-size: 0;
}
.slideLink {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.slideLink_hover {
  color: #ffffff;
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -100%;
  background-image: linear-gradient(180deg, #0678c1 0%, #0b9fe1 100%);
  transition: all 0.5s ease 0s;
  padding: 10px;
  text-align: center;
  width: 140px;
  margin: 0 auto;
  display: block;
  border-radius: 4px 4px 0px 0px;
  z-index: 1;
}

.slideLink:hover .slideLink_hover {
  bottom: 0;
  transition: all 0.5s ease 0s;
}

.blog_img img {
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0 0 15px #0c143d2b;
  width: 100%;
}
.blog_img span {
  display: block;
  position: relative;
  height: 185px;
}
.blog_img span::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
}
.b_content {
  padding: 15px;
  padding-top: 30px;
  background-color: $white;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 0 15px #0c143d2b;
  position: relative;
  min-height: 160px;
  height: 160px;
}

.b_content::before {
  content: '';
  position: absolute;
  top: -25px;
  left: 25px;
  width: 50px;
  height: 50px;
  background: url(../../../assets/images/homepage/quote-alt-right.svg) center center no-repeat;
  background-size: 20px 20px;
  border: 4px solid $white;
  background-color: $blue_color;
  border-radius: 50%;
}

.b_content h3 {
  font-size: 18px;
  color: #141414;
  margin-bottom: 10px;
  font-weight: 600;
}
.b_content p {
  font-size: 14px;
  font-weight: 400;
  color: #948a99;
  text-align: left;
}

.b_content a.custombtn {
  font-size: 14px;
  padding: 10px 15px;
  margin-top: 10px;
}
.customer {
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer h4 {
  font-size: 20px;
  color: #141414;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;
}

.customer i {
  font-size: 16px;
  font-weight: 400;
  color: #948a99;
  line-height: 16px;
}

.customer a {
  color: $orange_color;
}

/* slick start */
.slick-initialized .slick-slide {
  outline: medium none !important;
}

.slick-prev,.slick-next {
  width: 45px !important;
  height: 45px !important;
  // background-color: $blue_color !important;
  border-radius: 50%;
  z-index: 1;
  background-image: linear-gradient(90deg, #eb621e 0%, #fdce06 150%) !important;
}

.slick-prev {
  left: -45px !important;
}

.slick-next {
  right: -45px !important;
}

.slick-prev:before {
  content: '' !important;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../../../assets/images/homepage/arrow-left.svg) center center no-repeat;
  background-size: 20px 20px;
}

.slick-next:before {
  content: '' !important;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 0;
  right: 0;
  background: url(../../../assets/images/homepage/arrow-right.svg) center center no-repeat;
  background-size: 20px 20px;
}
/* slick end */
/* blogs end */

/* faq start */
.faq {
  padding: 50px 0px;
  background-color: $white;
}

.faq_list {
  padding: 50px;
  border: 1px solid rgb(227, 227, 227);
  border-radius: 4px;
  box-shadow: rgba(0,0,0,0.5) 0px 4px 6px 0px;
  border: 1px solid #dddddd;
}

.faq_list .MuiAccordion-root {
  margin-bottom: 30px;
  box-shadow: 0px 2px 10px -1px rgba(0,0,0,0.2), 0px 1px 0px 0px rgba(0,0,0,0.14), 0px 1px 0px 0px rgba(0,0,0,0.12) !important;

}
.faq_list .MuiAccordion-root:last-child {
  margin-bottom: 0px;
}

.faq_list .MuiAccordionSummary-content {
  border-bottom: 1px solid rgb(227, 227, 227);
  padding: 26px 0px;
  margin: 0 !important;
}

.faq_list .MuiAccordionSummary-content p {
  font-size: 18px;
  line-height: 18px;
  color: $black;
  font-weight: 600;
  flex-basis: 100%;
}
.faq_list .MuiAccordionDetails-root {
  display: block;
}
.faq_list .MuiAccordionDetails-root p {
  font-size: 18px;
  line-height: 34px;
  color: #948a99;
  font-weight: 500
}
.faq_list .points {
  font-size: 18px;
  line-height: 34px;
  color: #948a99;
  font-weight: 500
}

.faq_list .MuiAccordionDetails-root p ul {
  list-style-type: none;
  padding-left: 0px;
}

.faq_list .MuiIconButton-root {
  padding: 5px !important;
  border-radius: 4px !important;
  background-color: $blue_color !important;
}

.faq_list .MuiIconButton-root:hover{
  background-color: $blue_color_hover !important;
}

.faq_list .MuiSvgIcon-root {
  fill: $white !important;
  width: 30px !important;
  height: 30px !important;
}

.faq_list .MuiIconButton-edgeEnd {
  margin-right: 0 !important;
}

.faq_list .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0 !important;
}
/* faq end */

/* sidenav start*/
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #b4692f;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.header .hamburger {
  display: none;
  float: right;
  width: 33px;
  height: 33px;
  padding: 8px 5px 8px 5px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background: #948a99;
  border-radius: 0;
  width: 20px;
  height: 3px;
}

.hamburger-box {
  width: 20px;
  height: 17px;
}

.hamburger-inner::before {
  top: -6px;
}

.hamburger-inner::after {
  bottom: -6px;
}
.sidenav {
  background-color: #202020;
  height: 100%;
  left: -200px;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: all 0.5s ease 0s;
  width: 200px;
  z-index: 99;

}
.sidenav.is-active {
  left: 0px;
}
.hamburger--squeeze.is-active {
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: $black;
  }
}
.sidenav ul {
  list-style-type: none;
  padding-top: 95px;
  display: block;
}
.sidenav ul li {
  display: block;
}
.sidenav a {
  color: #aaa !important;
  display: block;
  font-size: 16px;
  padding: 10px 20px 10px 45px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.sidenav a:hover {
  color: $orange_color_hover !important;
}

.sidenav a.active {
  background-color: transparent;
  color: $orange_color_hover !important;
}
.sidenav a.activeblog {
  background-color: transparent;
  color: $orange_color_hover !important;
}
.sidenav ul li.res_hideshow {
  display: none;
  padding: 10px 20px 10px 45px;
}
/* sidenav end*/

/* scroll top fixed button start */
.back-top {
  position: fixed;
  opacity: 0;
  right: 40px;
  bottom: 100px;
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 18px;
  z-index: 1;
  visibility: hidden;
  background-color: $blue_color;
  border-radius: 50%;
  transition: all .6s ease;
  color: $white;
  cursor: pointer;
}
.back-top:hover {
  background-color: $blue_color_hover;
  color: $white;
}
.back-top-visible {
  opacity: 1;
  visibility: visible;
}
/* scroll top fixed button end */


.logo_bb, .logo_cc {
  color: $blue_color;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 500;
  cursor: pointer;
  padding: 5px 0px;
}

.logo_cc {
  background: linear-gradient(90deg,$orange_color,$orange_color_hover);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
}

.is-danger {
  color: #ff8585;
  font-size: 14px;
  margin-top: 5px;
}

/* toast notification start */
.toastmain {
  position: fixed;
  top: 100px;
  right: 30px;
  border-radius: 4px;
  border: 0;
  box-shadow: none;
  background: linear-gradient( 90deg, #eb621e, #fdce06);
  transition: all 1s ease 0s;
  visibility: hidden;
  opacity: 0;
}
.toastmain.show {
  visibility: visible;
  opacity: 1;
  transition: all 1s ease 0s;
  transform: translate(0px, 10px);
}
.toastmain .toast-body {
  font-size: 26px;
  color: $black;
}
/* toast notification end */

.hideOnBlog{
  display: none !important;
}

.tollfree_number {
  position: fixed;
  right: 30px;
  top: 90px;
  font-size: 16px;
  line-height: normal;
  display: inline-block;
  color: $white;
  text-align: right;
  background-color: transparent;
  background-image: linear-gradient(180deg, $blue_color 0%, $blue_color_hover 100%);
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 2;
  span {
    font-size: 23px;
    line-height: 23px;
    display: block;
    color: $white;
    margin-top: 5px;
  }
}

.landing_whatsapp_icon {
  position: fixed;
  display: inline-block;
  left: 30px;
  top: 90px;
  z-index: 2;
}

.tollfree_number:hover {
  color: $white;
}
/* Css for Contact us Modal start from here */
.contact-button{
  text-align: center;
}
.submit-button{
  margin: 0px auto;
  text-align: center;
}
.contact-us{
  margin-top: 20px;
  font-size: 16px;
  line-height: normal;
  display: inline-block;
  color: #ffffff;
  text-align: right;
  background-color: transparent;
  background-image: linear-gradient(
  180deg, #0678c1 0%, #0b9fe1 100%);
  padding: 7px 14px;
  border-radius: 4px;
  z-index: 2;
}
.contact-modal .MuiDialogContent-root {
  padding: 20px;
  color: $black;
  font-weight: 700;
  font-size: 20px;
  max-width: 100%;
}
.form-group .form-control{
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  padding: 5px;
}
.form-group .form-control:focus{
  border: 1px solid $blue_color_hover;
}
.close-icon{
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
}
/* Css for Contact us Modal end */

.footer_logo_link {
  cursor: pointer;
}
.contact-us-heading h5 {
  font-size: 24px;
  margin-bottom: 10px;
}
.footer_address {
  margin-top: 10px;
  p {
    color: $black !important;
    position: relative;
    padding-left: 20px;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 400;
    &:first-child {
      font-size: 14px;
      font-weight: 500;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    span {
      color: $blue_color !important;
      position: absolute;
      top: 0;
      left: 0;
    }
    a {
      color: $black !important;
      &:hover {
        color: $orange_color !important;
      }
    }
  }
}